<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-09-17 18:44 -->
<!-- Description:  -->
<template>
  <div>
    <h4>Control diario</h4>
    <div class="mb-3 row">
      <div class="col-3">
        <input
          type="date"
          v-model="date"
          class="form-control"
          @change="reload()"
        />
      </div>
    </div>
    <h4>Ventas - Ingresos</h4>
    <TableControlView :list="list" :key="`tb_01_${date}`"></TableControlView>
    <div class="row">
      <div class="col-lg-4">
        <ControlTableResumeByEmployee
          ref="elControlTableResumeByEmployee"
        ></ControlTableResumeByEmployee>
      </div>
      <div class="col-lg-4">
        <TableResumePayMethod ref="elTableResumePayMethod">
        </TableResumePayMethod>
      </div>
    </div>
    <h4>Controles / Tratamientos</h4>
    <TableControlView
      :list="treatments"
      :noPrices="true"
      :key="`tb_no_pri_${date}`"
    >
    </TableControlView>

    <h4>Egresos</h4>
    <div class="row">
      <div class="col-md-6 col-12">
        <AddOutputsByDate
          :key="date"
          :date="date"
          @outputs-ready="updateOutputs($event)"
        >
        </AddOutputsByDate>
      </div>
    </div>

    <h4>Saldos</h4>
    <div class="row">
      <div class="col-md-6 col-12">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr v-for="(it5, i03) in balance" :key="`res_ult_${i03}`">
              <th>{{ it5.pay_method }}</th>
              <th>
                <app-span-money
                  moneyCode="1"
                  :quantity="it5.amount_paid"
                ></app-span-money>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import myMoment from "src/lt/utils/myMoment.js";
import { DentalClinicService } from "../service.js";
import AddOutputsByDate from "./AddOutputsByDate.vue";
import TableControlView from "./TableControlView.vue";
import ControlTableResumeByEmployee from "./ControlTableResumeByEmployee.vue";
import TableResumePayMethod from "./TableResumePayMethod.vue";
import _ from "lodash";

// script content
export default {
  name: "ControlDiario",
  components: {
    AddOutputsByDate,
    TableControlView,
    ControlTableResumeByEmployee,
    TableResumePayMethod,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: [],
    date: myMoment().getCurrentDate(),
    treatments: [],
    resumeInputs: [],
    outputs: [],
  }),

  computed: {
    balance() {
      let results = JSON.parse(JSON.stringify(this.resumeInputs));
      for (let it in this.outputs) {
        let res = results.filter((x) => {
          return x.payment_method_id == this.outputs[it].payment_method_id;
        });
        if (res.length > 0) {
          res[0].amount_paid += this.outputs[it].amount_paid;
        } else {
          results.push(this.outputs[it]);
        }
      }
      return results;
    },
  },

  // watch: {},

  mounted() {
    this.reload();
  },

  methods: {
    async reload() {
      this.$refs.elControlTableResumeByEmployee.reset();
      this.$refs.elTableResumePayMethod.reset();
      let response = await DentalClinicService.getControl({
        date: this.date,
      });
      let res = response.sales;
      this.treatments = response.treatments;
      this.list = res;
      this.$refs.elControlTableResumeByEmployee.generate(res);
      this.resumeInputs = this.$refs.elTableResumePayMethod.generate(res);
      //
    },
    updateOutputs(values) {
      let outputs = [];
      let groups = _.groupBy(values, "payment_method_id");
      for (let it in groups) {
        let amount = groups[it].reduce(function(p, c) {
          return p + parseFloat(c.quantity);
        }, 0);
        outputs.push({
          payment_method_id: it,
          pay_method: "",
          amount_paid: amount * -1,
        });
      }
      this.outputs = outputs;
    },
    //
    //
  },
};
</script>
<style scoped></style>
