<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-04 17:01 -->
<!-- Description:  -->
<template>
  <div>
    <button
      :disabled="!cashbox.id"
      class="btn btn-primary mb-3"
      @click="
        $refs.elModalForm.show();
        $refs.elOutputForm.reset();
      "
    >
      Registrar Gasto
    </button>
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th>Razon</th>
          <th>Obs.</th>
          <th>Monto</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in cashbox.outputs" :key="i">
          <td>{{ l.reason_name }}</td>
          <td>{{ l.observation }}</td>
          <td>
            <app-span-money
              moneyCode="1"
              :quantity="l.quantity"
            ></app-span-money>
          </td>
          <td class="p-0">
            <button class="btn btn-danger btn-sm" @click="deleteQuestion(l.id)">
              <i class="fa fas fa-trash"></i>
            </button>
          </td>
        </tr>
        <tr
          v-for="(out01, i02) in cashbox.output_total"
          :key="`I_table_${i02}`"
        >
          <th colspan="2">Total</th>
          <th>
            <app-span-money moneyCode="1" :quantity="out01"></app-span-money>
          </th>
        </tr>
      </tbody>
    </table>

    <app-modal-basic ref="elModalForm">
      <OutputForm
        ref="elOutputForm"
        :cashRegisterId="cashbox.id"
        @submitted="
          $refs.elModalForm.hide();
          getCashbox();
        "
      ></OutputForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { AdminService } from "../../lt/admin-module/AdminService.js";
import OutputForm from "../../lt/admin-module/outputs/Form.vue";

// script content
export default {
  name: "AddOutputsByDate",
  components: {
    OutputForm,
  },

  // directives

  props: {
    date: {
      require: true,
    },
  },

  data: () => ({
    id: null,
    cashbox: {},
    list: [],
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.getCashbox();
  },

  methods: {
    async getCashbox() {
      this.cashbox = {};
      await AdminService.getIdMyCashRegister();
      var res = await AdminService.getCashRegisters({
        date: this.date,
        r_user_id: this.$store.state.user.id,
      });
      if (res.data.length > 0) {
        let box = await AdminService.getCashRegister(res.data[0].id);
        this.cashbox = box;
        this.$emit("outputs-ready", box.outputs);
      }
    },
    async deleteQuestion(id) {
      if (window.confirm("Eliminar?")) {
        await AdminService.deleteCROutput(this.cashbox.id, id);
        this.getCashbox();
      }
    },
    //
    //
  },
};
</script>
<style scoped></style>
